@import './scss/theme.scss';
@import './scss/button.scss';
@import './scss/form.scss';

html {
  height: 100%;

  body {
    height: 100%;
    font-size: 16px;
    // font-family: 'Jost', sans-serif;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    background: $vr-white;
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.max-w-400 {
  max-width: 400px;
}

.dashboard-wrapper {
  // padding-top: 103px;
  // font-family: 'Jost', sans-serif;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;

  .dashboard-title-section {
    h5 {
      font-size: 24px;
      margin-bottom: 11px;
      padding-left: 15px;
    }
  }

  .cp-portal-section {
    overflow: hidden;

    .cp-page-header {
      padding-top: 8px;
      padding-bottom: 16px;

      .header-title {
        color: #000;
        font-size: 25px;
        font-weight: 600;
        margin: 0;
      }
    }

    .cp-portal-dashboard-wrapper {
      // height: 64vh;
      overflow: hidden;
      position: relative;

      .cp-portal-wrapper-body {
        // height: 66vh;
        overflow: hidden;
      }

      .upload-box-main {
        h4 {
          font-size: 26px;
          line-height: 1;
          color: #454cad;
          margin-bottom: 9px;
          text-align: center;
          font-weight: 600;
        }

        p {
          font-size: 16px;
          color: #5f6982;
          text-align: center;
        }

        .inside-upload-box {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 7px;

          .form-label {
            font-weight: 500;
          }
        }

        .uploader {
          display: block;
          clear: both;
          margin: 0 auto;
          width: 100%;
          max-width: 600px;

          .image-upload {
            float: left;
            clear: both;
            width: 100%;
            padding: 2rem 1.5rem;
            text-align: center;
            background: #fff;
            border-radius: 7px;
            border: 3px solid #eee;
            transition: all 0.2s ease;

            .icon {
              svg {
                width: 83px;
                font-size: 65px;
                color: #454cad;
              }
            }
          }
        }
      }
    }

    .table-wrap {
      overflow-x: auto;
      overflow-y: auto;
      // max-height: 420px;
      // max-height: calc(100vh - 20%);
      max-height: calc(100vh - 37vh);
      height: 100%;

      .table {
        min-width: 1000px !important;
        width: 100%;
        background: #fff;

        thead {
          &.thead-grey {
            background: #F5F5F5;
            border: #fff;
            position: sticky;
            top: -0.1px;
            z-index: 2;
          }

          th {
            border: none;
            padding: 15px 15px;
            font-size: 15px;
            color: #ffa000;
            font-weight: 600;
            background-color: #fff9f0;
            vertical-align: middle;
          }
        }

        tbody {
          tr {
            td {
              border: none;
              padding: 12px 15px;
              border-bottom: 2px solid #ececec;
              font-size: 15px;
              font-weight: 400;
              vertical-align: middle;

              .action-col {
                a {
                  background-color: #e9f0f7;
                  color: #64748b;
                  height: 35px;
                  width: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 0.375rem;
                  font-size: 11px;
                  transition-duration: .2s;

                  svg {
                    width: 1.2rem;
                  }

                  &.edit:hover {
                    color: #2d77ed;
                    background-color: #cfe3fc;
                  }

                  &.delete:hover {
                    color: #ef4444;
                    background-color: #fee2e2;
                  }

                  &.view:hover {
                    color: #2db33d;
                    background-color: #d0fccf;
                  }
                }
              }

              .select-dropdown {
                .MuiFormControl-root {
                  margin: 0;

                  .MuiFormLabel-root {
                    top: -1px;
                    font-size: 13px;

                    &.MuiInputLabel-shrink {
                      top: inherit;
                    }
                  }

                  .MuiInputBase-root {
                    border-radius: 30px;
                    font-size: 13px;

                    &.approved {
                      color: #00c800;
                      font-weight: 600;
                      font-size: 13px;
                    }

                    &.rejected {
                      color: #e42020;
                      font-weight: 600;
                      font-size: 13px;
                    }

                    .MuiSelect-select {
                      padding: 7px 0px;
                      min-width: 50px;
                      font-size: 13px;
                      padding-left: 16px;
                    }
                  }
                }
              }
            }

            &.table-loader {
              .custom-content-loader {
                min-height: 300px;
              }
            }
          }

          .no-data-found {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 220px;
            width: 100%;

            figure {
              display: block;
              padding: 0;
              margin: 0;

              svg {
                width: 4rem;
                height: 4rem;
                font-size: 1rem;
                color: #e1e1e1;
                margin-bottom: 10px;
              }
            }

            label {
              font-size: 1.5rem;
              font-weight: 300;
              color: #ccc;
            }
          }
        }

      }
    }

    .export-data-footer {
      // position: absolute;
      z-index: 999;
      bottom: 14px;
      right: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      justify-content: end;

      p {
        margin: 0;
        font-size: 0.85rem;
        font-weight: 500;
        color: #333335;
      }

      .btn {
        padding: 7px 16px;
        max-width: 156px;
        width: 100%;
        border-radius: 4px;
        font-size: 0.8rem;

        svg {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
  }
}

body {
  .offcanvas-bottom {
    max-width: 840px;
    margin: 0 auto;
    min-height: 90%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 0px;
    outline: 0;

    .offcanvas-header {
      justify-content: center;

      .top-line {
        max-width: 233px;
        background-color: #D9D9D9;
        height: 10px;
        border-radius: 30px;
        width: 100%;
        cursor: pointer;
        transition-duration: .2s;

        &:hover {
          background-color: #c8c7c7;
        }
      }
    }

    .offcanvas-body {
      padding: 13px 50px;

      .off-canvas-title {
        margin-bottom: 35px;
      }
    }
  }
}

.form-group {
  position: relative;

  .form-label {
    font-weight: 400;

    small {
      font-size: 12px;
      color: #515151;
    }
  }

  .error-msg {
    position: absolute;
    // font-family: "Jost", sans-serif;
    font-family: "Roboto", sans-serif;
    left: 14px;
    font-weight: 400;
    bottom: -37px;
    font-size: 13px;
    color: #d32f2f;
  }

  &.image-upload {
    input {
      width: 110px;
      padding: 6px 16px;
      background: #f8f9fa;
    }

    .select-file {
      font-weight: 400;
      font-size: 14px;
      margin-top: 5px;
      color: #0035c1;
      position: absolute;
      top: 35px;
      left: 122px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.MuiAlert-root {
  margin-bottom: 30px;
  position: absolute;
  top: 50px;
  right: 30px;
  max-width: 400px;
  width: 100%;
  z-index: 99999;
}

.list-group {
  .badge {
    &.badge-primary {
      background-color: #0035c1;
    }
  }
}

p {
  &.error-msg {
    color: #e42020 !important;
    font-size: 14px !important;
  }
}

.form-group {
  &.image-upload {
    &.file-upload {
      border: 1px dashed #ff9f00;
      padding: 7px;
      border-radius: 10px;

      .inside-upload-box {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        gap: 9px;

        .form-label {
          font-size: 13px;
          margin: 0;
          max-width: 205px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            color: #0047ff;
          }
        }
      }
    }
  }
}

.MuiStack-root {
  display: block;

  .MuiPagination-root {
    display: block;

    .MuiPagination-ul {
      li {
        .Mui-selected {
          background-color: rgb(252, 213, 107) !important;
          font-weight: 600 !important;
        }
      }
    }
  }
}

.country-phone-input input {
  width: 100% !important;
}

.modal-wrapper {
  h5 {
    color: #414141;
    font-size: 30px;
    font-weight: 600;
    margin: 10px 0 12px;
    text-align: center;
  }

  p {
    color: #585858;
    font-size: 17px;
    margin: 0 auto;
    text-align: center;
  }
}

.lead-cancel-modal {
  .modal-body {
    .icon-box {
      display: flex;
      justify-content: center;
      margin: 16px 0 0;
      text-align: center;

      .material-icons {
        align-items: center;
        border: 2px solid #f15e5e;
        border-radius: 100%;
        color: #f15e5e;
        display: flex;
        font-size: 2.5rem;
        font-style: normal;
        height: 60px;
        justify-content: center;
        line-height: 0;
        padding: 14px;
        width: 60px;
      }
    }
  }

  .modal-footer {
    border: none;
    gap: 10px;
    justify-content: center;
    padding: 9px 0 31px;

    .btn {
      border: none;
      border-radius: 35px;
      color: #fff;
      line-height: normal;
      margin: 0 5px;
      min-height: 40px;
      min-width: 120px;
      padding: .375rem .75rem;
      text-decoration: none;
      transition: all .4s;
    }

    .btn-primary {
      background: #c1c1c1;
    }

    .btn-danger {
      background: #f15e5e;
    }
  }
}

.lead-list-modal {
  .error-msg {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 13px !important;
    color: #d32f2f !important;
    margin: 3px 14px 0;
    position: absolute;
  }

  .col-md-6 {
    margin-top: 32px;
    position: relative;
  }

  .special-label {
    background: #fff;
    padding: 0px 3px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
  }

  .country-phone-input {
    position: relative;

    .react-tel-input {
      input {
        border-radius: 10px;
        padding: 16px 56px;
      }
    }
  }
}

.lead-confirmation-modal {
  .modal-header {
    position: relative;
    .modal-title {
      text-align: center;
      font-size: 18px;
      width: 100%;
      p {
        font-size: 16px;
        color: #6cbe5c;
      }
    }
    .btn-close {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
  .modal-body {
    padding: 16px 24px !important;
    label {
      font-weight: 400;
      color: #727272;
      font-size: 16px;
    }
    p {
      font-weight: 600;
      font-size: 16px;
    }
  }
}