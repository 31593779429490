/* Common */
$vr-global-font: 'Jost', sans-serif;
$vr-container: 1110px;

/* Theme Default */
$vr-bg-default: #ffffff;
$vr-white: #fff;
$vr-dark: #000;

$vr-base: #D7EFFF;
$vr-base-400: #D4DBDE;
$vr-base-300: #7199B5;
$vr-base-200: #B6D5EA;
$vr-base-100: #95B6D0;

$vr-primary: #0047FF;
$vr-primary-hover: #003BD3;
$vr-primary-300: #0091FF;
$vr-primary-200: #00B8FF;
$vr-primary-100: #70D7FF;

$vr-secondary: #616161;
$vr-secondary-hover: #494949;
$vr-secondary-100: #AFAFAF;
$vr-secondary-200: #919191;
$vr-secondary-300: #7C7C7C;

$vr-error: #F2271C;

/* Theme Green */
$vr-bg-green: #DAF0E4;

/* Theme Orange */
$vr-bg-orange: #F5F0EB;

/*Theme Yello*/
$vr-bg-yellow: #FFBD12;