@import './theme.scss';

button {
    &.btn {
        border: 0;
        &.login-btn-main {
            color: $vr-primary;
            padding: 11px 18px;
            max-width: 206px;
            background-color: #fff;
            border-radius: 77px;
            font-size: 20px;
            text-align: center;
            margin-top: 30px;
            border: 0;
            color: #0047FF;
            &:hover {
                background-color: #edecec;
                color: #0047FF;
            }
        }
        &.btn-cp-primary {
            padding: 9px 19px;
            max-width: 251px;
            width: 100%;
            border-radius: 30px;
            font-size: 16px;
            color: #fff;
            background-color: #fbb33a;
        }
        &.btn-cp-secondary {
            padding: 9px 19px;
            max-width: 251px;
            width: 100%;
            border-radius: 30px;
            font-size: 16px;
            color: #fff;
            background-color: #616161;
        }
    }
}
